import axios from "axios";

const API_URL = process.env.REACT_APP_WP_DOMAIN;

const login = (username, password) => {
  return axios
    .post(API_URL + "/wp-json/jwt-auth/v1/token", {
      username,
      password,
    })
    .then((res) => {
        if (res.data.data.nicename) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("authenticated");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserInfo = () => {
  if(localStorage.getItem("user")) {
    const userID = JSON.parse(localStorage.getItem("user")).data.id;
    return axios.get(API_URL + "/wp-json/wp/v2/users/" + userID + "/");
  }
};


const AuthService = {
  login,
  logout,
  getCurrentUser,
  getCurrentUserInfo,
}

export default AuthService;
