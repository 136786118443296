import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faPlus, faChartLine } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import AuthService from "../services/auth.service";

const SidebarCampaign = () => {
    let params = useParams();
    
    const [campaignsList, setCampaigns] = useState([]);
    const [currentItems, setCurrentItems] = useState(null);
    const location = useLocation();
    const isReport = location.pathname.slice(0, location.pathname.lastIndexOf('/')) === "/reports" || location.pathname === '/reports' ? true : false;

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 20;

    function Items({ currentItems }) {
        return (
            <>
            {currentItems &&
                currentItems.map((campaign) => (
                    <tr className={parseInt(params.id) === parseInt(campaign.id) ? "cursor-pointer active" : "cursor-pointer"} key={campaign.id} onClick={()=> handleClick(campaign.id)}>
                        <td className="col-name">{campaign.title.rendered}</td>
                        <td className="col-status"><span className={campaign.acf.campaign_status ? "status active" : "status"}></span></td>
                        <td>{Moment(campaign.modified).format('DD/MM/YYYY')}</td>
                    </tr>
            ))}
            </>
        );
    }

    React.useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        const endOffset = itemOffset + itemsPerPage;
        if(currentUser) {
            let campaignApiUrl = process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/campaigns/?author=' + currentUser.data.id;
            if(isReport && currentUser.data.role === 'administrator') {
                campaignApiUrl = process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/campaigns';
            }
            axios.get(campaignApiUrl)
            .then(res => {
                setCampaigns(res.data);
                setCurrentItems(res.data.slice(itemOffset, endOffset));
                setPageCount(Math.ceil(res.data.length / itemsPerPage));
            })
            .catch(err => console.log(err));
        }
    }, [itemOffset, itemsPerPage, isReport]);

    const navigate = useNavigate();
    const handleClick = (id) => {
        if(isReport){
            navigate(`/reports/${id}`);
        }else{
            navigate(`/campaign/${id}`);
        }
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % campaignsList.length;
        setItemOffset(newOffset);
    };

    // console.log(location.pathname);
    return (
        <div className="spark-sidebar">
            {isReport ? (
                <>
                    <h2 className="spark-sidebar__heading"><FontAwesomeIcon icon={faChartLine} /> Reports</h2>
                    <div>Select Campaigns</div>
                </>
            ) : (
                <>
                    <h2 className="spark-sidebar__heading"><FontAwesomeIcon icon={faBullseye} /> Campaigns</h2>
                    <Button variant="primary" as={Link} to="/" className="btn-new-campaign"><FontAwesomeIcon icon={faPlus} /> New Campaign</Button>
                    <div>{campaignsList.length} Campaigns</div>
                </>
            )}
            <div className="table-wrapper table-wrapper--campaign">
                <Table>
                    <thead>
                        <tr>
                            <th className="col-name">Name</th>
                            <th className="col-status"></th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Items currentItems={currentItems} />
                    </tbody>
                </Table>
            </div>
            {campaignsList.length > itemsPerPage &&
                <ReactPaginate
                    size="sm"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    pageClassName="spark-pagination__item"
                    pageLinkClassName="spark-pagination__link"
                    previousClassName="spark-pagination__item"
                    previousLinkClassName="spark-pagination__link"
                    nextClassName="spark-pagination__item"
                    nextLinkClassName="spark-pagination__link"
                    breakLabel="..."
                    breakClassName="spark-pagination__item"
                    breakLinkClassName="spark-pagination__link"
                    containerClassName="spark-pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            }
        </div>
    );
  };
  
  export default SidebarCampaign;