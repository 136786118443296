import React from 'react';
import SidebarAccount from '../../components/SidebarAccount';
import { Card } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Account = () => {

    return (
        <div className="main__wrapper profile account">
            <SidebarAccount />
            <div className="main__content">
                <div className="main__block">
                    <h1 className="page-heading">Account</h1>
                </div>
                <div className="main__block border-bottom-0">
                    <p>You are subscribed to the spark*activation letter writing plan.</p>
                    <Row>
                        <Col md="6" lg="4" className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Plan 1</Card.Title>
                                    <Card.Text>
                                        Vestibulum tincidunt nunc tellus, dictum maximus ante elementum at.
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="6" lg="4" className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Plan 2</Card.Title>
                                    <Card.Text>
                                        Vestibulum tincidunt nunc tellus, dictum maximus ante elementum at.
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="6" lg="4" className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Plan 3</Card.Title>
                                    <Card.Text>
                                        Vestibulum tincidunt nunc tellus, dictum maximus ante elementum at.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Account;