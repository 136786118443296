
import FooterLogo from './footer-logo.svg';
import Container from 'react-bootstrap/Container';

const MainFooter = () => {
  return (
    <footer className="footer">
        <Container>
            <img src={FooterLogo} className="footer__logo" alt="footer logo" />
            <div className="footer__links">
              <a href="mailto:adrian@sparkadvocacy.ca,robert@sparkadvocacy.ca">Need help?</a>
            </div>
        </Container>
    </footer>
  );
};

export default MainFooter;