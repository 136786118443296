import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AuthService from '../../services/auth.service';
import logo from '../../components/logo-activation.svg';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);
    
        AuthService.login(username, password).then(
            () => {
                localStorage.setItem("authenticated", true);
                navigate("/");
            },
            (err) => {
                const resMessage =
                (err.response &&
                    err.response.data &&
                    err.response.data.message) ||
                    err.message ||
                err.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    };

    return (
        <div className="login">
            <div className="login__content">
                <img src={logo} className="login__logo" alt="logo activation" />
                <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Username" name="username" onChange={onChangeUsername} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="password" placeholder="Password" name="password" onChange={onChangePassword} />
                    </Form.Group>
                    {message && (
                        <div className="mt-3">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <Form.Group className="text-center">
                        <Button variant="primary" type="submit">Login {loading && ( <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>)}</Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}

export default Login;