import React from 'react';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../services/auth.service';

const SidebarAccount = () => {
    const navigate = useNavigate(); 
    const logOut = () => {
        navigate("/login");
        AuthService.logout();
    };
    return (
        <div className="spark-sidebar">
            <h2 className="spark-sidebar__heading"><FontAwesomeIcon icon={faUser} /> Account</h2>
            <ul className="navbar-nav spark-sidebar__menu">
                <li><NavLink className={({ isActive }) =>isActive ? 'nav-link active' : 'nav-link'} to='/account'>Account Summary</NavLink></li>
                <li><NavLink className={({ isActive }) =>isActive ? 'nav-link active' : 'nav-link'} to='/profile'>Profile Settings</NavLink></li>
                <li><span className="nav-link" onClick={logOut}>Sign Out</span></li>
            </ul>
        </div>
    );
  };
  
  export default SidebarAccount;