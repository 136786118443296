import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Create from './pages/Create/Create';
import Login from './pages/Login/Login';
import Reports from './pages/Reports/Reports';
import Account from './pages/Account/Account';
import ProfileSettings from './pages/Account/ProfileSettings';
import Campaign from './pages/Campaign/Campaign';
import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Content-Type"] = `application/json; charset=UTF-8`;
  } else
    delete axios.defaults.headers.common["Authorization"];
}

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname !== "/login" ? false : true;
  if(localStorage.getItem("user")) {
    const token = JSON.parse(localStorage.getItem("user")).data.token;
    if (token) {
      setAuthToken(token);
    }
  }
  return (
    <div className="App">
        {isLoginPage ? '' : <MainHeader />}
        <div className="main">
          <Routes>
            <Route exact path='/' element={<Create />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/reports' element={<Reports />} />
            <Route path='/reports/:id' element={<Reports />} />
            <Route exact path='/account' element={<Account />} />
            <Route exact path='/profile' element={<ProfileSettings />} />
            <Route path="/campaign/:id" element={<Campaign />} />
          </Routes>
          {isLoginPage ? <MainFooter /> : ''}
        </div>
    </div>
  );
};

export default App;
