import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={'/'}>
    <App />
  </BrowserRouter>
);
