import React, { useReducer, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import SidebarCampaign from '../../components/SidebarCampaign';
import './Create.css';
import { generateCampaignEmbedForm } from '../../utils/helpers';

const formReducer = (state, event) => {
  if (event.reset) {
    return {
      campaign_audiences: [],
      campaign_custom_contacts: [
        { campaign_custom_contacts_email: '', campaign_custom_contacts_name: '' },
      ]
    }
  }
  return {
    ...state,
    [event.name]: event.value
  }
}

const Create = () => {
  const [formData, setFormData] = useReducer(formReducer, {
    status: 'publish', 
    campaign_audiences: [], 
    campaign_custom_contacts: [
      { campaign_custom_contacts_email: '', campaign_custom_contacts_name: '' },
    ], 
    campaign_message_sent: 'Thank you, your letter has been sent.'
  });

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [preview, setPreview] = useState(false);
  const [howToInstall, setHowToInstall] = useState(false);
  const [status, setStatus] = useState(false);
  const [validations, setValidations] = useState({ title: '', campaign_letter: '', email1: '', email2: '', email3: '', email4: '', name1: '', name2: '', name3: '', name4: '' });
  const [embeddedCode, setEmbeddedCode] = useState("");
  const [isCampaignReload, setIsCampaignReload] = useState(true);
  const [copyEmbedCodeSuccess, setCopyEmbedCodeSuccess] = useState('');

  const audienceSets = {
    'Federal': 'federal-electoral-districts',
    'Alberta': 'alberta-electoral-districts-2017',
    'Manitoba': 'manitoba-electoral-districts-2018',
  };

  const handleAddContact = () => {
    setFormData({
        name: "campaign_custom_contacts",
        value: [
            ...formData.campaign_custom_contacts,
            { campaign_custom_contacts_name: '', campaign_custom_contacts_title: '', campaign_custom_contacts_email: '' }
        ]
    });
};

const handleDeleteContact = (index) => {
    const updatedContacts = formData.campaign_custom_contacts.filter((_, i) => i !== index);
    setFormData({
        name: "campaign_custom_contacts",
        value: updatedContacts
    });
};


  const validateAll = () => {
    const { title, campaign_letter } = formData;
    let isValid = true;

    if (!title) {
      validations.title = 'This field is required';
      isValid = false;
    }

    if (!campaign_letter) {
      validations.campaign_letter = 'This field is required';
      isValid = false;
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  }

  const validateField = (e) => {
    const { name } = e.target;
    const value = e.target.value;
    let message = '';

    if (!value) {
      message = 'This field is required';
    }

    setValidations({ ...validations, [name]: message });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'campaign_audiences') {
        // Directly set the selected value in the state
        setFormData({
            name: 'campaign_audiences',
            value: [value]  // Store the selected value as an array
        });
    } else if (name === 'campaign_status') {
        const isChecked = event.target.checked;
        setStatus(isChecked);
        setFormData({
            name,
            value: isChecked,
        });
    } else {
        setFormData({
            name,
            value,
        });
    }

    validateField(event);
}


  const handleCustomContactsChange = (event, i) => {
    const { campaign_custom_contacts } = formData;
    const { name, value } = event.target;
    const contactList = [...campaign_custom_contacts];
    contactList[i][name] = value;

    if (name.includes('email') && value && !/\S+@\S+\.\S+/.test(value)) {
      let message = 'Email format must be as example@mail.com';
      setValidations({ ...validations, [`email${i + 1}`]: message });
    } else {
      setValidations({ ...validations, [`email${i + 1}`]: '' });
    }

    setFormData({
      name: "campaign_custom_contacts",
      value: contactList
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const customContactList = formData.campaign_custom_contacts.filter(element => {
      return element.campaign_custom_contacts_email !== '';
    });

    // const greeting = formData.campaign_greeting ? `${formData.campaign_greeting} ${formData.campaign_custom_contacts.map(contact => contact.campaign_custom_contacts_name).filter(name => name).join(', ')}` : '';
    // const message = `${greeting ? greeting + '\n\n' : ''}${formData.campaign_letter}`;
   
    let message = `${formData.campaign_letter}`;
    
    const newData = {
      acf: {
        campaign_letter: message,
        campaign_status: formData.campaign_status,
        campaign_audiences: formData.campaign_audiences, 
        campaign_custom_contacts: customContactList,
        campaign_subject: formData.campaign_subject,
        campaign_greeting: formData.campaign_greeting,
        campaign_message_sent: formData.campaign_message_sent
      },
      status: formData.status,
      title: formData.title
    };

    const isValid = validateAll();

    if (isValid) {
      axios.post(`${process.env.REACT_APP_WP_DOMAIN}/wp-json/wp/v2/campaigns`, newData)
        .then(res => {
          const code = generateCampaignEmbedForm(res.data.id);
          setIsSuccess(true);
          setEmbeddedCode(code);
          axios.post(`${process.env.REACT_APP_WP_DOMAIN}/wp-json/wp/v2/campaigns/${res.data.id}/`, { acf: { campaign_embed_code: code } });
          setIsCampaignReload(false);
          setTimeout(() => {
            setIsCampaignReload(true);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  const handleClosePreview = () => setPreview(false);
  const handleShowPreview = () => setPreview(true);
  const handleCloseHowToInstallPreview = () => setHowToInstall(false);
  const handleShowHowToInstallPreview = () => setHowToInstall(true);
  const handleCopyEmbeddedForm = () => {
    try {
      navigator.clipboard.writeText(embeddedCode);
      setCopyEmbedCodeSuccess('Copied!');
    } catch (err) {
      setCopyEmbedCodeSuccess('Failed to copy!');
    }
  }

  return (
    <div className="main__wrapper create">
      {isCampaignReload && <SidebarCampaign />}
      <div className="main__content">
        <Form onSubmit={handleSubmit}>
          {loading && (<div className="loader"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>)}
          <div className="main__block">
            <h1 className="page-heading">New Campaign</h1>
            <Row>
              <Col xl="9">
                <Row className="align-items-center">
                  <Col xs="12" className="mb-4">
                    <Form.Group controlId="campaign_title">
                      <Form.Control type="text" placeholder="Title" className="input-campaign-title" name="title" value={formData.title || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.title} />
                      <Form.Control.Feedback type="invalid">{validations.title}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="status" 
                        name="campaign_status" 
                        value={formData.campaign_status || ''} 
                        onChange={handleChange} 
                        checked={status}
                      />
                      <i>Disable/enable form submissions</i>
                      <div className="ms-4 text-large">Status: {status ? 'ENABLED' : 'DISABLED'}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="main__block">
            <h2 className="mb-4">Step 1: Select Your Audience</h2>
            <Row>
              <Col xl="10">
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Automatic Audience: your participants will input</Form.Label>
                      <div className="mb-3">
                        <Form.Select name="campaign_audiences" onChange={handleChange}>
                          <option value="">Select audience</option>
                          {Object.keys(audienceSets).map((audience, index) => (
                            <option key={`audience-${index}`} value={audienceSets[audience]}>
                              {audience}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Label>Custom Contacts: not including automatic</Form.Label>
                    {formData.campaign_custom_contacts.map((contact, index) => (
                      <Form.Group key={index}>
                        <Form.Control
                          className="mb-3"
                          type="text"
                          placeholder={`Full Name`}
                          name="campaign_custom_contacts_name"
                          value={contact.campaign_custom_contacts_name || ''}
                          onChange={(e) => handleCustomContactsChange(e, index)}
                        />
                        <Form.Control
                          className="mb-3"
                          type="text"
                          placeholder={`Title`}
                          name="campaign_custom_contacts_title"
                          value={contact.campaign_custom_contacts_title || ''}
                          onChange={(e) => handleCustomContactsChange(e, index)}
                        />
                        <Form.Control
                          type="email"
                          placeholder={`Email Address`}
                          name="campaign_custom_contacts_email"
                          value={contact.campaign_custom_contacts_email || ''}
                          onChange={(e) => handleCustomContactsChange(e, index)}
                          isInvalid={!!validations[`email${index + 1}`]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {validations[`email${index + 1}`]}
                        </Form.Control.Feedback>
                        <div className="d-flex justify-content-end mt-2">
                          <Button 
                            variant="danger" 
                            style={{ fontSize: '12px', padding: '4px 10px' }} 
                            onClick={() => handleDeleteContact(index)}
                          >
                            Delete
                          </Button>
                        </div>
                        <hr className="mb-3"></hr>
                      </Form.Group>
                    ))}
                    <div className="d-flex justify-content-end mt-2">
                      <Button 
                        variant="secondary" 
                        style={{ fontSize: '12px', padding: '4px 10px' }} 
                        onClick={handleAddContact}
                      >
                        Add Custom Contact
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="main__block">
            <h2 className="mb-4">Step 2: Configure Your Letter</h2>
            <Row>
              <Col xl="10">
                <Form.Group controlId="campaign_subject" className="mb-3">
                  <Form.Text muted>Email subject</Form.Text>
                  <Form.Control type="text" placeholder="Subject" name="campaign_subject" value={formData.campaign_subject || ''} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="campaign_greeting" className="mb-3">
                  <Form.Text muted>Greeting</Form.Text>
                  <Form.Control type="text" placeholder="Greeting" name="campaign_greeting" value={formData.campaign_greeting || ''} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="letterContent" className="mb-3">
                  <Form.Control as="textarea" name="campaign_letter" placeholder="Dear MP,&#13;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae eros vitae sem mollis maximus at luctus neque. Phasellus et dapibus orci. Phasellus in felis ac nulla malesuada aliquam sagittis velit sed lectus pharetra…" value={formData.campaign_letter || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.campaign_letter} />
                  <Form.Control.Feedback type="invalid">{validations.campaign_letter}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="campaign_message_sent">
                  <Form.Text muted>Thank you message</Form.Text>
                  <Form.Control type="text" placeholder="Write a custom thank you message" name="campaign_message_sent" value={formData.campaign_message_sent || ''} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="main__block">
            <div className="form-actions justify-content-start">
              <Button variant="secondary" onClick={handleShowPreview}>Preview</Button>
              <Button variant="primary" type="submit" disabled={isSuccess ? 'disabled' : ''}>Save Changes</Button>
            </div>
          </div>
          <div className="main__block border-bottom-0">
            <h2 className="mb-4">Step 3: Get Your Embed Code</h2>
            <span className="mb-4 cursor-pointer" style={{ textDecoration: 'underline', display: 'block', color: 'black' }} onClick={handleShowHowToInstallPreview}>How to install?</span>
            <Row className="mb-4">
              <Col xl="10">
                <Form.Group>
                  <Form.Control as="textarea" placeholder="Embed code here" value={embeddedCode} onChange={(e) => setEmbeddedCode(e?.target?.value)} readOnly/>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="secondary" type="button" onClick={handleCopyEmbeddedForm} disabled={embeddedCode?.length === 0}>Copy Code</Button>
            {copyEmbedCodeSuccess}
          </div>
        </Form>
        <Modal className="campaign-preview" show={preview} onHide={handleClosePreview}>
          <Modal.Body>
            <h1 className="mb-3 text-uppercase">{formData.title ? formData.title : 'CAMPAIGN TITLE HEADING'}</h1>
            <Row>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="First Name" readOnly />
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Last Name" readOnly />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Email" readOnly />
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Postal Code" readOnly />
                </Form.Group>
              </Col>
            </Row>
            <p>Recipient(s)</p>
            <Form.Group className="mb-3">
              <Form.Control as="textarea" name="campaign_letter" placeholder="Dear MP,&#13;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae eros vitae sem mollis maximus at luctus neque. Phasellus et dapibus orci. Phasellus in felis ac nulla malesuada aliquam sagittis velit sed lectus pharetra…" value={formData.campaign_letter} readOnly />
            </Form.Group>
            <Button variant="primary" disabled="disabled">Send message</Button>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <div className="campaign-preview__back" onClick={handleClosePreview}>Back to Campaign editor</div>
          </Modal.Footer>
        </Modal>
        <Modal className="campaign-preview" show={howToInstall} onHide={handleCloseHowToInstallPreview}>
          <Modal.Body>
              <h1 className="mb-3 text-uppercase">How to Install?</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
              <div className="campaign-preview__back" onClick={handleCloseHowToInstallPreview}>Back to Campaign editor</div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Create;
