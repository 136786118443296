import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import SidebarCampaign from '../../components/SidebarCampaign';
  
const Campaign = () => {
    let params = useParams();
    const audienceSets = {
        'Federal': 'federal-electoral-districts',
        'Alberta': 'alberta-electoral-districts-2017',
        'Manitoba': 'manitoba-electoral-districts-2018',
      };

      const [customContacts, setCustomContacts] = useState([
        { campaign_custom_contacts_name: '', campaign_custom_contacts_title: '', campaign_custom_contacts_email: '' }
    ]);

    // Other state declarations...

    const [data, setData] = useState({});
    const [formData, setFormData] = useState({
        campaign_audiences: [],
        campaign_custom_contacts: [],
        campaign_message_sent: 'Thank you, your letter has been sent.',
    });
    
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
    const [validations, setValidations] = useState({ title: '', campaign_letter: '', email1: '', email2: '', email3: '', email4: '' });
    const [copyEmbedCodeSuccess, setCopyEmbedCodeSuccess] = useState('');
    const [preview, setPreview] = useState(false);
    const [howToInstall, setHowToInstall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCampaignReload, setIsCampaignReload] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    

    React.useEffect(() => {
        if (params.id) {
            // Reset form and data state when the campaign ID changes
            setFormData({
                campaign_audiences: [],
                campaign_custom_contacts: [],
                campaign_message_sent: 'Thank you, your letter has been sent.'
            });
            setIsCampaignLoaded(false);
    
            // Fetch campaign data from API
            axios.get(`${process.env.REACT_APP_WP_DOMAIN}/wp-json/wp/v2/campaigns/${params.id}`).then(
                (response) => {
                    let customContactsFromAPI = response.data.acf.campaign_custom_contacts || [
                        { campaign_custom_contacts_name: '', campaign_custom_contacts_title: '', campaign_custom_contacts_email: '' }
                    ];
    
                    const details = {
                        title: response.data.title.rendered,
                        campaign_status: response.data.acf.campaign_status,
                        campaign_audiences: response.data.acf.campaign_audiences,
                        campaign_custom_contacts: customContactsFromAPI,
                        campaign_letter: response.data.acf.campaign_letter,
                        campaign_embed_code: response.data.acf.campaign_embed_code,
                        campaign_subject: response.data.acf.campaign_subject,
                        campaign_greeting: response.data.acf.campaign_greeting,
                        campaign_message_sent: response.data.acf.campaign_message_sent,
                    };
    
                    setData(details);
                    setFormData(details);
                    setCustomContacts(customContactsFromAPI);
                    setIsCampaignLoaded(true);
                    setIsChange(false);
                    setValidations({ title: '', campaign_letter: '' });
                }
            );
        }
    }, [params.id]);
    

    const handleChange = event => {
        const { name, value } = event.target;
    
        if (name === 'campaign_audiences') {
            // Update the state with the value selected from the dropdown
            setData({ ...data, "campaign_audiences": [value] });
            setFormData({ ...formData, "campaign_audiences": [value] });
        } else if (name === 'campaign_status') {
            const isChecked = event.target.checked;
            setStatus(isChecked);
            setData({ ...data, "campaign_status": isChecked });
            setFormData({ ...formData, "campaign_status": isChecked });
        } else {
            setData({ ...data, [name]: value });
            setFormData({ ...formData, [name]: value });
        }
    
        setIsChange(true);
    };
    
    
    


    const handleCustomContactsChange = (event, index) => {
        const { name, value } = event.target;
        const updatedContacts = customContacts.map((contact, i) =>
            i === index ? { ...contact, [name]: value } : contact
        );
    
        setCustomContacts(updatedContacts);
        setIsChange(true);
    
        if (name === 'campaign_custom_contacts_email' && value && !/\S+@\S+\.\S+/.test(value)) {
            let message = 'Email format must be as example@mail.com';
            setValidations({ ...validations, [`email${index + 1}`]: message });
        } else {
            setValidations({ ...validations, [`email${index + 1}`]: '' });
        }
    };
    
    const handleAddContact = () => {
        setCustomContacts([...customContacts, { campaign_custom_contacts_name: '', campaign_custom_contacts_title: '', campaign_custom_contacts_email: '' }]);
        setIsChange(true);
    };
    
    const handleDeleteContact = (index) => {
        const updatedContacts = customContacts.filter((_, i) => i !== index);
        setCustomContacts(updatedContacts);
        setIsChange(true);
    };
    

    const validateAll = () => {
        const { title, campaign_letter } = formData;
        let isValid = true
    
        if (!title) {
          validations.title = 'This field is required';
          isValid = false
        }
    
        if (!campaign_letter) {
          validations.campaign_letter = 'This field is required';
          isValid = false
        }
    
        if (!isValid) {
          setValidations(validations);
        }
    
        return isValid;
    }
    
    const validateField = (e) => {
        const { name } = e.target
        const value = formData[name]
        let message = '';
    
        if (!value) {
          message = 'This field is required'
        }
    
        setValidations({ ...validations, [name]: message })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
    
        const newData = {
            acf: {
                campaign_letter: formData.campaign_letter,
                campaign_status: formData.campaign_status,
                campaign_audiences: formData.campaign_audiences, 
                campaign_custom_contacts: customContacts.filter(contact => contact.campaign_custom_contacts_email),
                campaign_subject: formData.campaign_subject,
                campaign_greeting: formData.campaign_greeting,  
                campaign_message_sent: formData.campaign_message_sent
            },
            status: formData.status,
            title: formData.title
        };
    
        const isValid = validateAll();
    
        if (isValid) {
            axios.post(`${process.env.REACT_APP_WP_DOMAIN}/wp-json/wp/v2/campaigns/${params.id}/`, newData)
                .then(res => {
                    console.log(res, 'Response after saving');
                    setIsSuccess(true);
                    setIsCampaignReload(false);
                    setTitle(data.title);
                    setTimeout(() => {
                        setIsCampaignReload(true);
                    }, 1);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 2000);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };
    

    const handleClosePreview = () => setPreview(false);
    const handleShowPreview = () => setPreview(true);
    const handleCloseHowToInstallPreview = () => setHowToInstall(false);
    const handleShowHowToInstallPreview = () => setHowToInstall(true);
    const handleCopyEmbeddedForm = () => {
      try {
        navigator.clipboard.writeText(data.campaign_embed_code);
        setCopyEmbedCodeSuccess('Copied!');
      } catch (err) {
        setCopyEmbedCodeSuccess('Failed to copy!');
      }
    }
    
    if(isCampaignLoaded) {
        return (
            <div className="main__wrapper create">
                {isCampaignReload && <SidebarCampaign />}
                <div className="main__content">
                    <Form onSubmit={handleSubmit}>
                        {loading && (<div className="loader"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>)}
                        <div className="main__block">
                            <h1 className="page-heading">{data.title}</h1>
                            <Row>
                                <Col xl="9">
                                    <Row className="align-items-center">
                                        <Col xs="12" className="mb-4">
                                            <Form.Group controlId="campaign_title">
                                                <Form.Control type="text" placeholder="Title" className="input-campaign-title" name="title" value={data.title || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.title} />
                                                <Form.Control.Feedback type="invalid">{validations.title}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12">
                                            <div className="d-flex align-items-center">
                                                <Form.Check
                                                    key={Math.random()}
                                                    type="switch"
                                                    id="status" name="campaign_status" value={data.campaign_status || ''} defaultChecked={data.campaign_status} onChange={handleChange}
                                                />
                                                <i>Disable/enable form submissions</i>
                                                <div className="ms-4 text-large">Status: {status ? 'ENABLED' : 'DISABLED'}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="main__block">
                            <h2 className="mb-4">Step 1: Select Your Audience</h2>
                            <Row>
                                <Col xl="10">
                                    <Row>
                                        <Col md="6">
                                        <Form.Group controlId="campaign_audiences">
                                            <Form.Label>Automatic Audience: your participants will input</Form.Label>
                                            <Form.Select name="campaign_audiences" value={formData.campaign_audiences[0] || ''} onChange={handleChange}>
                                                <option value="">Select an audience</option>
                                                {Object.keys(audienceSets).map((audience, index) => (
                                                    <option key={index} value={audienceSets[audience]}>
                                                        {audience}  {/* This is the label */}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                        </Col>
                                        <Col md="6">
                                   

                                        <Form.Label>Custom Contacts: not including automatic</Form.Label>
                                        {customContacts.map((contact, index) => (
                                            <Form.Group className="mb-3" key={index}>
                                                <Form.Control
                                                    className="mb-3"
                                                    type="text"
                                                    placeholder={`Full Name`}
                                                    name="campaign_custom_contacts_name"
                                                    value={contact.campaign_custom_contacts_name || ''}
                                                    onChange={(e) => handleCustomContactsChange(e, index)}
                                                />
                                                <Form.Control
                                                    className="mb-3"
                                                    type="text"
                                                    placeholder={`Title`}
                                                    name="campaign_custom_contacts_title"
                                                    value={contact.campaign_custom_contacts_title || ''}
                                                    onChange={(e) => handleCustomContactsChange(e, index)}
                                                />
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="campaign_custom_contacts_email"
                                                    value={contact.campaign_custom_contacts_email || ''}
                                                    onChange={(e) => handleCustomContactsChange(e, index)}
                                                    isInvalid={!!validations[`email${index + 1}`]}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations[`email${index + 1}`]}
                                                </Form.Control.Feedback>
                                                <div className="d-flex justify-content-end mt-2">
                                                    <Button variant="danger" 
                                                     style={{ fontSize: '12px', padding: '4px 10px' }}
                                                     onClick={() => handleDeleteContact(index)}>
                                                        Delete
                                                    </Button>
                                                </div>
                                                <hr className="mb-3"></hr>
                                            </Form.Group>
                                        ))}
                                        <div className="d-flex justify-content-end mt-2">
                                            <Button variant="secondary" 
                                            style={{ fontSize: '12px', padding: '4px 10px' }}
                                            onClick={handleAddContact}>Add Custom Contact</Button>
                                        </div>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="main__block">
                            <h2  >Step 2: Configure Your Letter</h2>
                            <Row>
                                <Col xl="10">
                                    <Form.Group controlId="campaign_subject" className="mb-3">
                                        <Form.Text muted>Email subject</Form.Text>
                                        <Form.Control type="text" placeholder="Subject" name="campaign_subject" value={data.campaign_subject || ''} onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Group controlId="campaign_greeting" className="mb-3">
                                        <Form.Text muted>Greeting</Form.Text>
                                        <Form.Control type="text" placeholder="Greeting" name="campaign_greeting" value={data.campaign_greeting || ''} onChange={handleChange} />
                                    </Form.Group>
                                    
                                    <Form.Group controlId="letterContent" className="mb-3">
                                        <Form.Control as="textarea" name="campaign_letter" placeholder="Dear MP,&#13;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae eros vitae sem mollis maximus at luctus neque. Phasellus et dapibus orci. Phasellus in felis ac nulla malesuada aliquam sagittis velit sed lectus pharetra…" value={data.campaign_letter || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.campaign_letter} />
                                        <Form.Control.Feedback type="invalid">{validations.campaign_letter}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="campaign_message_sent">
                                        <Form.Control type="text" placeholder="Write a custom thank you message" name="campaign_message_sent" value={data.campaign_message_sent || ''} onChange={handleChange} />
                                        <Form.Text muted>Thank you message</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="main__block">
                            <div className="form-actions justify-content-start">
                                <Button variant="secondary" onClick={handleShowPreview}>Preview</Button>
                                <Button variant="primary" type="submit" disabled={isChange ? '' : 'disabled'}>Save changes</Button>
                            </div>
                            {isSuccess && (
                                <div className="alert alert-success my-3">The campaign has been successfully updated!</div>
                            )}
                        </div>
                        <div className="main__block border-bottom-0">
                            <h2 className="mb-4">Step 3: Get Your Embed Code</h2>
                            <span className="mb-4 cursor-pointer" style={{ textDecoration: 'underline', display: 'block', color: 'black' }} onClick={handleShowHowToInstallPreview}>How to install?</span>
                            <Row className="mb-4">
                                <Col xl="10">
                                    <Form.Group>
                                        <Form.Control as="textarea" placeholder="Embed code here" value={data.campaign_embed_code} readOnly  />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="secondary" type="button" onClick={handleCopyEmbeddedForm}>Copy Code</Button>
                            {copyEmbedCodeSuccess}
                        </div>
                    </Form>
                    <Modal className="campaign-preview" show={preview} onHide={handleClosePreview}>
                        <Modal.Body>
                            <h1 className="mb-3 text-uppercase">{formData.title ? formData.title : 'CAMPAIGN TITLE HEADING'}</h1>
                            <Row>
                            <Col lg="6">
                                <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="First Name" readOnly />
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Last Name" readOnly />
                                </Form.Group>
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col lg="6">
                                <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Email" readOnly />
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Postal Code" readOnly />
                                </Form.Group>
                            </Col>
                            </Row>
                            <p>Recipient(s)</p>
                            <Form.Group className="mb-3">
                            <Form.Control as="textarea" name="campaign_letter" placeholder="Dear MP,&#13;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae eros vitae sem mollis maximus at luctus neque. Phasellus et dapibus orci. Phasellus in felis ac nulla malesuada aliquam sagittis velit sed lectus pharetra…" value={formData.campaign_letter} readOnly />
                            </Form.Group>
                            <Button variant="primary" disabled="disabled">Send message</Button>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-start">
                            <div className="campaign-preview__back" onClick={handleClosePreview}>Back to Campaign editor</div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="campaign-preview" show={howToInstall} onHide={handleCloseHowToInstallPreview}>
                        <Modal.Body>
                            <h1 className="mb-3 text-uppercase">How to Install?</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-start">
                            <div className="campaign-preview__back" onClick={handleCloseHowToInstallPreview}>Back to Campaign editor</div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Campaign;