import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCancel } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import SidebarAccount from '../../components/SidebarAccount';
import './Profile.css';
import AuthService from "../../services/auth.service";

const ProfileSettings = () => {
    const currentUser = AuthService.getCurrentUser();
    const initial_state = {
        id: currentUser ? currentUser.data.id : "",
        first_name: currentUser ? currentUser.data.firstName : "",
        last_name: currentUser ? currentUser.data.lastName : "",
        spark_user_organization: currentUser ? currentUser.data.organization : "",
        email: currentUser ? currentUser.data.email : "",
    };
    const [data] = useState(initial_state);
    const [user, setUser] = useState(initial_state);
    const [formData, setFormData] = useState({});
    const [isChange, setIsChange] = useState(false);
    // const imgUpload = useRef(null);
    const [validations, setValidations] = useState({email: ''});
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChange = event => {
        setUser({ ...user, [event.target.name]: event.target.value });
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setIsChange(true);
    };

    const validateAll = () => {
        const { email } = user;
        let isValid = true
    
        // if ( !spark_user_full_name ) {
        //   validations.spark_user_full_name = 'This field is required';
        //   isValid = false
        // }
    
        if ( !email ) {
          validations.email = 'This field is required';
          isValid = false
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            validations.email = 'Email format must be as example@mail.com';
            isValid = false
        }
    
        if (!isValid) {
          setValidations(validations);
        }
        
        return isValid;
    }

    const validateField = (e) => {
        const { name } = e.target
        const value = user[name]
        let message = '';
        if (!value) {
            message = 'This field is required';
        }
        
        if(name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            message = 'Email format must be as example@mail.com';
        }

        setValidations({...validations, [name]: message })
    }

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        var existingUser = localStorage.getItem('user');
        existingUser = existingUser ? JSON.parse(existingUser) : {};

        const isValid = validateAll();
        if(isValid) {
            const fd = new FormData();
            
            if(formData.email) {
                fd.append('email', formData.email);
            }
            if(formData.first_name) {
                fd.append('first_name', formData.first_name);
            }
            if(formData.last_name) {
                fd.append('last_name', formData.last_name);
            }
            if(formData.spark_user_organization) {
                fd.append('acf.spark_user_organization', formData.spark_user_organization);
            }
            axios.post(`${process.env.REACT_APP_WP_DOMAIN}/wp-json/wp/v2/users/${currentUser.data.id}/`, fd )
            .then(res => {
                setLoading(false);
                setIsSuccess(true);
                setIsChange(false);
                if(formData.email) {
                    existingUser['data']['email'] = formData.email;
                    localStorage.setItem('user', JSON.stringify(existingUser));
                }
                if(formData.first_name) {
                    existingUser['data']['firstName'] = formData.first_name;
                    localStorage.setItem('user', JSON.stringify(existingUser));
                }
                if(formData.last_name) {
                    existingUser['data']['lastName'] = formData.last_name;
                    localStorage.setItem('user', JSON.stringify(existingUser));
                }
                if(formData.spark_user_organization) {
                    existingUser['data']['organization'] = formData.spark_user_organization;
                    localStorage.setItem('user', JSON.stringify(existingUser));
                }

                setTimeout(() => {
                    setIsSuccess(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err)
            })
            
        }else{
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFormData({});
        setUser(data);
        setValidations({});
        setIsChange(false);
    }

    return (
        <div className="main__wrapper profile">
            <SidebarAccount />
            <div className="main__content">
                <div className="main__block">
                    <h1 className="page-heading">Profile Settings</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    {loading && ( <div className="loader"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>)}
                    <div className="main__block profile-info-block">
                        <Row>
                            <Col md="3" xl="2" className="mb-1 mb-md-0">First Name</Col>
                            <Col md="7" xl="5">
                                <Form.Group controlId="profileFirstName">
                                    <Form.Control type="text" name="first_name" value={user.first_name || ''} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="3" xl="2" className="mb-1 mb-md-0">Last Name</Col>
                            <Col md="7" xl="5">
                                <Form.Group controlId="profileLastName">
                                    <Form.Control type="text" name="last_name" value={user.last_name || ''} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="3" xl="2" className="mb-1 mb-md-0">Organization</Col>
                            <Col md="7" xl="5">
                                <Form.Group controlId="profileOrganization">
                                    <Form.Control type="text" name="spark_user_organization" value={user.spark_user_organization || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.spark_user_organization} />
                                    <Form.Control.Feedback type="invalid">{validations.spark_user_organization}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="3" xl="2" className="mb-1 mb-md-0">Email</Col>
                            <Col md="7" xl="5">
                                <Form.Group controlId="profileEmail">
                                    <Form.Control type="email" name="email" value={user.email || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!validations.email} />
                                    <Form.Control.Feedback type="invalid">{validations.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="main__block border-bottom-0">
                        {isSuccess && (
                            <div className="alert alert-success mb-3">Your profile has been successfully updated!</div>
                        )}
                        <div className="form-actions">
                            <Button variant="primary" type="submit" disabled={isChange ? '' : 'disabled'}><FontAwesomeIcon icon={faPaperPlane} /> Save</Button>
                            <Button variant="secondary" onClick={handleCancel} disabled={isChange ? '' : 'disabled'}><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
  };
  
  export default ProfileSettings;