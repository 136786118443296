import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import logo from './logo.svg';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faChartLine } from '@fortawesome/free-solid-svg-icons';
import AuthService from "../services/auth.service";

const MainHeader = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [latestReport, setLatestReport] = useState('');

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (!loggedInUser) {
      navigate("/login");
      return;
    }
    if(currentUser){
      let campaignApiUrl = process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/campaigns/?author=' + currentUser.data.id;
      if(currentUser.data.role === 'administrator') {
        campaignApiUrl = process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/campaigns';
      }
      axios.get(campaignApiUrl)
      .then(res => {
        if(res.data) {
          setLatestReport(res.data[0].id);
        }
      })
      .catch(err => console.log(err));
    }
  }, [navigate,currentUser]);
  if (currentUser) {
    return (
      <Navbar collapseOnSelect expand="lg" className="sideBarMenu">
        <img src={logo} className="sideBarMenu__logo d-block d-lg-none" alt="logo" />
        <Navbar.Toggle aria-controls="headerNavbar" />
        <Link to="/account" className="sideBarMenu__profile">
          <div className="sideBarMenu__profile-image">{currentUser.data.firstName ? currentUser.data.firstName.charAt(0) : currentUser.data.nicename.charAt(0)}</div>
          <div className="sideBarMenu__profile-name">{!currentUser.data.firstName && !currentUser.data.lastName ? currentUser.data.nicename : currentUser.data.firstName + ' ' + currentUser.data.lastName}</div>
        </Link>
        <Navbar.Collapse id="headerNavbar">
          <ul className="navbar-nav">
            <li>
              <NavLink className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              } to='/'>
                <FontAwesomeIcon icon={faBullseye} /> Campaigns
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              } to={`reports/${latestReport ? latestReport : ''}`}>
                <FontAwesomeIcon icon={faChartLine} /> Reports
              </NavLink>
            </li>
          </ul>
        </Navbar.Collapse>
        <div className="sideBarMenu__footer mt-auto d-none d-lg-block">
          <img src={logo} className="sideBarMenu__logo" alt="logo" />
          <a href="mailto:adrian@sparkadvocacy.ca,robert@sparkadvocacy.ca">Need help?</a>
        </div>
      </Navbar>
    );
  }
};

export default MainHeader;