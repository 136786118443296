import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CSVLink } from "react-csv";
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import SidebarCampaign from '../../components/SidebarCampaign';
import Moment from 'moment';
import AuthService from "../../services/auth.service";

const Reports = () => {
    let params = useParams();
    const currentUser = AuthService.getCurrentUser();
    const [submissionsList, setSubmissionsList] = useState([]);
    const [currentItems, setCurrentItems] = useState(null);
    const [campaignDetails, setCampaignDetails] = useState([]);
    const [hasID, setHasID] = useState(false);
    const [submissionLoaded, setSubmissionLoaded] = useState(false);
    const [campaignLoaded, setCampaignLoaded] = useState(false);
    const [organization, setOrganization] = useState(null);

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 20;

    let fileHeaders = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Postal Code", key: "postal_code" },
        { label: "Date", key: "timestamp" }
    ];

    if(currentUser.role === 'administrator') {
        fileHeaders = [
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Email", key: "email" },
            { label: "Postal Code", key: "postal_code" },
            { label: "Organization", key: "organization" },
            { label: "Date", key: "timestamp" }
        ];
    }

    function Items({ currentItems }) {
        return (
            <tbody>
            {currentItems &&
                currentItems.map((item) => (
                <tr key={item.id}>
                    <td>{item.first_name}</td>
                    <td>{item.last_name}</td>
                    <td>{item.email}</td>
                    <td>{item.postal_code}</td>
                    {currentUser.data.role === 'administrator' ? <td>{organization}</td> : ''}
                    <td>{Moment(item.timestamp).format('DD/MM/YYYY')}</td>
                </tr>
            ))}
            </tbody>
        );
    }

    React.useEffect(() => {
        if(params.id){
            const endOffset = itemOffset + itemsPerPage;
            setHasID(true);
            axios.get(process.env.REACT_APP_WP_DOMAIN + '/wp-json/spark/v1/lw-submissions/' + params.id).then(
                (response) => {
                    setSubmissionsList(response.data);
                    if(response.data && response.data.length > 0) {
                        setCurrentItems(response.data.slice(itemOffset, endOffset));
                        setPageCount(Math.ceil(response.data.length / itemsPerPage));
                    }
                    console.log(response);
                    setSubmissionLoaded(true);
                }
            );
            axios.get(process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/campaigns/' + params.id).then(
                (response) => {
                    console.log(response);
                    setCampaignDetails(response.data);
                    setCampaignLoaded(true);
                    if(currentUser.data.role === 'administrator') {
                        axios.get(process.env.REACT_APP_WP_DOMAIN + '/wp-json/wp/v2/users/' + response.data.author).then((responseAuthor)=> {
                            if(responseAuthor.data.acf.spark_user_organization){
                                setOrganization(responseAuthor.data.acf.spark_user_organization)
                            }
                        });
                    }
                }
            );
        }
    }, [params.id, itemOffset, itemsPerPage, currentUser.data.role]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % submissionsList.length;
        setItemOffset(newOffset);
    };
    
    return (
        <div className="main__wrapper report">
            <SidebarCampaign />
            {hasID && submissionLoaded && campaignLoaded && ( 
            <div className="main__content">
                <div className="main__block">
                    <h1 className="page-heading mb-0">{campaignDetails ? campaignDetails.title.rendered : ''}</h1>
                </div>
                <div className="main__block">
                    <h2 className="mb-4">Statistics</h2>
                    <Table className="w-auto" borderless>
                        <tbody>
                            <tr>
                                <td>Total Submissions:</td>
                                <td>{submissionsList.length}</td>
                            </tr>
                            <tr>
                                <td>Last Used:</td>
                                <td>{submissionsList.length > 0 ? Moment(submissionsList[0].date).format('DD/MM/YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td>Date Created:</td>
                                <td>{campaignDetails ? Moment(campaignDetails.date).format('DD/MM/YYYY') : ''}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="main__block border-bottom-0">
                    <h2 className="mb-4">Results</h2>
                    <div className="table-wrapper">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>FIRST NAME</th>
                                    <th>LAST NAME</th>
                                    <th>EMAIL</th>
                                    <th>POSTAL CODE</th>
                                    {currentUser.data.role === 'administrator' && <th>ORGANIZATION</th>}
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <Items currentItems={currentItems} />
                        </Table>
                    </div>
                    {submissionsList.length > itemsPerPage &&
                        <ReactPaginate
                            size="sm"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            pageClassName="spark-pagination__item"
                            pageLinkClassName="spark-pagination__link"
                            previousClassName="spark-pagination__item"
                            previousLinkClassName="spark-pagination__link"
                            nextClassName="spark-pagination__item"
                            nextLinkClassName="spark-pagination__link"
                            breakLabel="..."
                            breakClassName="spark-pagination__item"
                            breakLinkClassName="spark-pagination__link"
                            containerClassName="spark-pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    }
                    {submissionsList.length > 0 &&
                    <CSVLink className="btn btn-primary"
                        headers={fileHeaders}
                        data={submissionsList}
                        filename="results.csv"
                        target="_blank"
                    >
                        Export CSV
                    </CSVLink>
                    }
                </div>
            </div>
            )}
        </div>
    );
  };
  
  export default Reports;